<template>
  <components is="EmpresaForm" type='editar'></components>
</template>

<script>
  import  EmpresaForm from './EmpresaForm';
  export default {

    name: 'configuracionInit',
    components: {
      EmpresaForm
    },
  }
</script>

<style lang="css" scoped>
</style>
